import {createReducer} from '@reduxjs/toolkit'
import {
  describeSeatingPlan,
  selectPrice,
  selectLocation,
  setShowAccessibilityMode,
  addPlaceToBasket,
  selectPlace,
  unselectPlace,
  setError,
  ERROR,
} from '../actions/seating'
import {SeatingState} from '../types'
import {checkout} from '../actions/checkout'
import {closeNativeModal} from '../actions/modals'
import {RESERVE_TICKETS} from '../actions/reservation'
import {isPlaceReservedError} from '../selectors/reservation'

const defaultState: SeatingState = {
  plan: null,
  loading: false,
  error: ERROR.NO_ERROR,
  places: [],
  selectedPrice: null,
  selectedZone: null,
  showAccessibilityMode: false,
  planReservations: {},
}

export const seating = createReducer<SeatingState>(defaultState, builder => {
  builder
    .addCase(describeSeatingPlan.fulfilled, (state, action) => {
      return {
        ...state,
        ...action.payload,
      }
    })
    .addCase(selectPrice.fulfilled, (state, action) => ({
      ...state,
      selectedPrice: action.payload,
    }))
    .addCase(addPlaceToBasket.fulfilled, (state, action) => ({...state, places: action.payload.places}))
    .addCase(selectLocation, (state, action) => ({...state, selectedZone: action.payload}))
    .addCase(checkout.fulfilled, state => ({...state, error: ERROR.NO_ERROR, loading: false}))
    .addCase(checkout.pending, state => ({...state, loading: true}))
    .addCase(RESERVE_TICKETS.FAILURE, (state, action: any) => {
      const error = isPlaceReservedError(action) ? ERROR.SEATS_UNAVAILABLE : ERROR.SOMETHING_WENT_WRONG
      return {...state, error, loading: false}
    })
    .addCase(setError, (state, action) => ({...state, error: action.payload, loading: false}))
    .addCase(setShowAccessibilityMode, (state, action) => ({...state, showAccessibilityMode: action.payload}))
    .addCase(closeNativeModal, state => ({...state, loading: false}))
    .addCase(selectPlace.fulfilled, (state, action) => ({...state, places: action.payload.places}))
    .addCase(unselectPlace.fulfilled, (state, action) => ({...state, places: action.payload.places}))
})
