import {IWixAPI} from '@wix/yoshi-flow-editor'
import {DETAILS_ROUTE} from '../../../commons/constants/navigation'
import {ReservationState} from '../../../commons/enums'
import {Navigation, RouteParams, ThankYouMessageState} from '../types'
import {isPreview} from '../../../commons/utils/wix-code-api'

const getParts = (wixCodeApi: IWixAPI): string[] => {
  const {baseUrl, url, path} = wixCodeApi.location
  // fallback for weirder cases
  if (!baseUrl || !url.includes(baseUrl)) {
    if (!url.includes(baseUrl) && url.includes('document') && isPreview(wixCodeApi)) {
      return path.slice(path.indexOf('document') + 2)
    }

    return path.slice(1)
  }

  const strippedUrl = url.split('?')[0]
  const route = strippedUrl.replace(new RegExp(`${baseUrl}\\/?`), '').split('/')
  return route
}

const getRoute = (path: string[]): DETAILS_ROUTE => {
  return (path[2] || DETAILS_ROUTE.DETAILS) as DETAILS_ROUTE
}

export const getRouteParams = (wixCodeApi: IWixAPI): RouteParams => {
  const path = getParts(wixCodeApi)
  switch (getRoute(path)) {
    case DETAILS_ROUTE.ORDER:
      return {
        reservationId: path[3],
        reservationState: path[4] as ReservationState,
      }
    case DETAILS_ROUTE.THANK_YOU_MESSAGES:
      return {
        thankYouMessageState: path[3] as ThankYouMessageState,
      }
    case DETAILS_ROUTE.ONLINE_CONFERENCING: {
      const conferencingState = path[3]
      if (
        conferencingState === DETAILS_ROUTE.ONLINE_CONFERENCING_DISABLED ||
        conferencingState === DETAILS_ROUTE.ONLINE_CONFERENCING_PROVIDER_ERROR
      ) {
        return {conferencingState}
      } else {
        return {}
      }
    }
    default:
      return {}
  }
}

export const parseLocation = (wixCodeApi: IWixAPI): Navigation => {
  const path = getParts(wixCodeApi)
  const {query} = wixCodeApi.location

  return {
    sectionPath: getSectionPath(wixCodeApi, path),
    slug: getSlug(wixCodeApi, path),
    route: getRoute(path),
    ...getRouteParams(wixCodeApi),
    shouldNavigateBack: false,
    query,
  }
}

const getSectionPath = (wixCodeApi: IWixAPI, path: string[]) => {
  if (isPreview(wixCodeApi) && path?.length === 1) {
    return ''
  }

  return path[0] || ''
}

const getSlug = (wixCodeApi: IWixAPI, path: string[]) => {
  if (isPreview(wixCodeApi) && path?.length === 1) {
    return path[0]
  }

  return path[1] || ''
}
