import {AnyAction} from 'redux'
import {CheckoutState} from '../types'
import {CHECKOUT_STEPS} from '../constants/constants'
import {
  SET_BUYER_DETAILS,
  NEXT_STEP,
  EDIT_STEP,
  USE_BUYER_DETAILS,
  SET_TICKET_DETAILS,
  NEXT_FORM_CLICKED,
  SET_EXPANDED_TICKET_INDEX,
  SET_VALID_PAYMENT_ADDED,
  CLEAR_CHECKOUT,
} from '../actions/checkout'

const defaultState: CheckoutState = {
  useBuyerDetails: false,
  currentStep: CHECKOUT_STEPS[0],
  validPaymentAdded: false,
  buyerDetails: null,
  ticketsDetails: [],
  validTicketsDetails: [],
  expandedTicketIndex: 0,
}

export const checkout = (state = defaultState, action: AnyAction): CheckoutState => {
  switch (action.type) {
    case SET_BUYER_DETAILS:
      return {
        ...state,
        buyerDetails: action.payload.buyerDetails,
      }
    case SET_TICKET_DETAILS:
      return setTicketDetails(state, action)
    case SET_VALID_PAYMENT_ADDED:
      return {
        ...state,
        validPaymentAdded: action.payload.validPaymentAdded,
      }
    case USE_BUYER_DETAILS:
      return handleUseBuyerDetails(state, action)
    case NEXT_STEP:
      return {
        ...state,
        currentStep: action.payload.nextStep,
      }
    case EDIT_STEP:
      const {currentStep, validPaymentAdded} = action.payload
      return {
        ...state,
        currentStep,
        validPaymentAdded,
      }
    case NEXT_FORM_CLICKED:
      return {
        ...state,
        expandedTicketIndex: state.expandedTicketIndex + 1,
      }
    case SET_EXPANDED_TICKET_INDEX:
      return {
        ...state,
        expandedTicketIndex: action.payload.index,
      }
    case CLEAR_CHECKOUT:
      return defaultState
    default:
      return state
  }
}

const handleUseBuyerDetails = (state: CheckoutState, action: AnyAction): CheckoutState => {
  const {
    buyerDetails: {firstName, lastName, email},
  } = state
  const shouldUseBuyerDetails = action.payload.useBuyerDetails
  const buyerDetails = shouldUseBuyerDetails
    ? {firstName, lastName, email}
    : {firstName: null, lastName: null, email: null}
  const ticketsDetails = [...state.ticketsDetails]

  ticketsDetails[action.payload.index] = {
    ...ticketsDetails[action.payload.index],
    ...buyerDetails,
  }

  return {
    ...state,
    ticketsDetails,
    useBuyerDetails: shouldUseBuyerDetails,
  }
}

const setTicketDetails = (state: CheckoutState, action: AnyAction) => {
  const ticketsDetails = [...state.ticketsDetails]
  const validTicketsDetails = [...state.validTicketsDetails]

  ticketsDetails[action.payload.index] = action.payload.details
  validTicketsDetails[action.payload.index] = action.payload.valid

  return {
    ...state,
    ticketsDetails,
    validTicketsDetails,
  }
}
