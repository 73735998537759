import {AnyAction} from 'redux'
import {CANCEL_RESERVATION, RESERVE_TICKETS} from '../actions/reservation'
import {Reservation} from '../types'

const defaultState: Reservation = {
  data: null,
  error: false,
}

export const reservation = (state = defaultState, action: AnyAction): Reservation => {
  switch (action.type) {
    case RESERVE_TICKETS.SUCCESS:
      return {...state, error: false, data: action.payload}
    case CANCEL_RESERVATION.SUCCESS:
      return {...state, error: false, data: null}
    case RESERVE_TICKETS.FAILURE:
      return {...state, data: null, error: true}
    default:
      return state
  }
}
